<template>
  <div>
    <validation-observer ref="textInput">
      <div class="field__header">
        Details
      </div>

      <v-row class="py-0">
        <v-col cols="12" sm="4" class="py-0">
          <CustomTextInput
            header="Title / Salutation"
            placeholder="Mr/Mrs"
            :value="contact.salutation"
            :maxlength="150"
            @input="(val) => $emit('input', val, 'salutation')"
          />
        </v-col>
        <v-col cols="12" sm="4" class="py-0">
          <CustomTextInput
            header="Suffix / Credentials"
            placeholder="Jr., Sr., MD, NP"
            :value="contact.credentials"
            :maxlength="150"
            @input="(val) => $emit('input', val, 'credentials')"
          />
        </v-col>
        <v-col cols="12" sm="4" class="py-0">
          <CustomDropdown
            header="Gender"
            placeholder="Select an option"
            :items="[
              { label: 'Male', value: 'M' },
              { label: 'Female', value: 'F' },
              { label: 'Other', value: 'O' },
            ]"
            :value="contact.gender"
            @input="(val) => $emit('input', val, 'gender')"
          />
        </v-col>
      </v-row>

      <v-row class="py-0">
        <v-col cols="12" sm="4" class="py-0">
          <CustomTextInput
            header="First Name"
            :value="contact.firstName"
            :maxlength="150"
            @input="(val) => $emit('input', val, 'firstName')"
          />
        </v-col>
        <v-col cols="12" sm="4" class="py-0">
          <CustomTextInput
            header="Middle Name"
            :value="contact.middleName"
            :maxlength="150"
            @input="(val) => $emit('input', val, 'middleName')"
          />
        </v-col>
        <v-col cols="12" sm="4" class="py-0">
          <CustomTextInput
            header="Last Name"
            :value="contact.lastName"
            :maxlength="150"
            @input="(val) => $emit('input', val, 'lastName')"
          />
        </v-col>
      </v-row>

      <v-divider class="mt-5 mb-6" />

      <v-row class="py-0">
        <v-col cols="12" sm="4" class="py-0">
          <CustomDropdown
            header="Is Test Contact"
            placeholder="Select an option"
            :items="[
              { label: 'Yes', value: true },
              { label: 'No', value: false },
            ]"
            :value="contact.isTest"
            @input="(val) => $emit('input', val, 'isTest')"
          />
        </v-col>
        <v-col cols="12" sm="4" class="py-0">
          <CustomTextInput
            header="Contact Type"
            :value="contact.contactType"
            :maxlength="150"
            @input="(val) => $emit('input', val, 'contactType')"
          />
        </v-col>
        <v-col cols="12" sm="4" class="py-0">
          <CustomDropdown
            header="Source"
            placeholder="Select an option"
            item-text="name"
            item-value="id"
            :items="available_sources"
            :value="contact.source"
            @change="(val) => $emit('input', val, 'source')"
          />
        </v-col>
      </v-row>

      <v-row v-if="selectedAccount.accountType === 'HCP'" class="py-0">
        <v-col cols="12" sm="4" class="py-0">
          <CustomTextInput
            header="NPI"
            :value="contact.npi"
            :maxlength="150"
            @input="(val) => $emit('input', val, 'npi')"
            @change="(val) => $emit('input', val, 'npi')"
          />
        </v-col>
        <v-col cols="12" sm="4" class="py-0">
          <CustomTextInput
            header="Specialty"
            :value="contact.specialty"
            :maxlength="150"
            @input="(val) => $emit('input', val, 'specialty')"
          />
        </v-col>
      </v-row>

      <template v-if="customFields.length > 0">
        <v-divider class="mt-5 mb-3" />
        <div class="field__header">
          Custom Fields
        </div>
        <v-row class="py-0">
          <v-col v-for="field in customFields" :key="field.fieldName" cols="12" sm="4" class="py-0">
            <CustomTextInput
              v-if="['text', 'number'].includes(field.editType)"
              :header="field.name"
              :type="field.editType"
              :value="contact.customFields[field.fieldName]"
              :required="field.required"
              @input="(val) => $emit('input', val, 'customFields', field.fieldName)"
            />
            <CustomDropdown
              v-if="['choice', 'database', 'yes_or_no'].includes(field.editType)"
              :header="field.name"
              :value="customFieldDropdowns[field.fieldName]"
              :items="field.editChoices"
              :required="field.required"
              @change="(val) => handleCustomFieldDropdownChange(val, field.fieldName, false)"
            />
            <CustomTextInput
              v-if="customFieldDropdowns[field.fieldName] === '___ADD_NEW_VALUE___'"
              :header="'New Value for ' + field.name"
              :required="field.required"
              @input="(val) => handleCustomFieldDropdownChange(val, field.fieldName, true)"
            />
            <CustomDatePicker
              v-if="field.editType === 'date'"
              :header="field.name"
              :date.sync="contact.customFields[field.fieldName]"
              :required="field.required"
              @input="(val) => $emit('input', val, 'customFields', field.fieldName)"
            />
          </v-col>
        </v-row>
      </template>
    </validation-observer>

    <v-divider class="mt-5 mb-3" />

    <validation-observer ref="textInputEmail">
      <div class="field__header">
        Emails
      </div>
      <v-simple-table v-if="contact.contactEmails.length > 0">
        <thead>
          <tr class="search-table__header">
            <th style="width: 25%">
              Email
            </th>
            <th style="width: 25%">
              Source
            </th>
            <th>Default</th>
            <th>Subscribed</th>
            <th>Opt Out Info</th>
            <th />
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(emailObj, index) in contact.contactEmails"
            :key="index"
          >
            <td>
              <CustomTextInput
                v-model="emailObj.emailAddress"
                placeholder="Email"
                :maxlength="150"
                :email="true"
              />
            </td>
            <td>
              <CustomDropdown
                v-model="emailObj.source"
                placeholder="Source"
                :items="available_sources"
                item-text="name"
                return-object
              />
            </td>
            <td>
              <v-checkbox
                v-model="emailObj.isDefault"
                color="#2b84eb"
                @change="handleDefaultEmailChange(emailObj)"
              />
            </td>
            <td>
              <v-checkbox
                v-model="emailObj.isSubscribed"
                color="#2b84eb"
              />
            </td>
            <td>
              <span v-if="emailObj.isSubscribed || !emailObj.id">
                -
              </span>
              <span v-else>
                Date: {{ getDateWithDayNameAndTime(emailObj.optOutDate) }}<br>
                Reason: {{ emailObj.optOutReason ? emailObj.optOutReason : 'Unknown' }}
              </span>
            </td>
            <td class="text-center">
              <v-icon
                class="pointer"
                medium
                @click="removeEmail(index)"
              >
                mdi-close
              </v-icon>
            </td>
          </tr>
        </tbody>
      </v-simple-table>

      <div class="mt-4">
        <a href="javascript:void(0);" class="add-more mt-4" @click="addEmail">
          Add an Email +
        </a>
      </div>
    </validation-observer>

    <v-divider class="mt-5 mb-4" />

    <validation-observer ref="textInputPhone">
      <div class="field__header">
        Phones
      </div>
      <v-simple-table v-if="contact.contactPhones.length > 0">
        <thead>
          <tr class="search-table__header">
            <th style="width: 20%">
              Phone
            </th>
            <th>
              Phone Default
            </th>
            <th>
              Phone Subscribed
            </th>
            <th>
              SMS Default
            </th>
            <th>
              SMS Subscribed
            </th>
            <th style="width: 10%">
              Is Fax
            </th>
            <th style="width: 20%">
              Source
            </th>
            <th />
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(phoneObj, index) in contact.contactPhones"
            :key="index"
          >
            <td>
              <CustomTextInput
                v-model="phoneObj.phone"
                class="flex-1 mr-2"
                placeholder="Phone"
                :maxlength="150"
              />
            </td>
            <td>
              <v-checkbox
                v-model="phoneObj.isPhoneDefault"
                class="custom-fields-row-checkbox mt-1 mr-1"
                @change="handleDefaultPhoneChange(phoneObj, 'phone')"
              />
            </td>
            <td>
              <v-checkbox
                v-model="phoneObj.isPhoneSubscribed"
                class="custom-fields-row-checkbox mt-1 mr-1"
              />
            </td>
            <td>
              <v-checkbox
                v-model="phoneObj.isSmsDefault"
                class="custom-fields-row-checkbox mt-1 mr-1"
                @change="handleDefaultPhoneChange(phoneObj, 'sms')"
              />
            </td>
            <td>
              <v-checkbox
                v-model="phoneObj.isSmsSubscribed"
                class="custom-fields-row-checkbox mt-1 mr-1"
              />
            </td>
            <td>
              <v-checkbox
                v-model="phoneObj.isFax"
                class="custom-fields-row-checkbox mt-1 mr-1"
              />
            </td>
            <td>
              <CustomDropdown
                v-model="phoneObj.source"
                placeholder="Source"
                :items="available_sources"
                item-text="name"
                return-object
              />
            </td>
            <td class="text-center">
              <v-icon
                class="pointer"
                medium
                @click="removePhone(index)"
              >
                mdi-close
              </v-icon>
            </td>
          </tr>
        </tbody>
      </v-simple-table>

      <div class="mt-4">
        <a href="javascript:void(0);" class="add-more" @click="addPhone">
          Add a Phone +
        </a>
      </div>
    </validation-observer>

    <v-divider class="mt-5 mb-4" />

    <validation-observer ref="textInputLocation">
      <div class="field__header">
        Locations
      </div>
      <v-row
        v-for="(locationObj, index) in contact.contactLocations"
        :key="index"
        class="py-0"
      >
        <v-col cols="11">
          <v-row>
            <v-col cols="4" class="py-0">
              <CustomTextInput
                v-model="locationObj.company"
                placeholder="Company"
                :maxlength="150"
              />
            </v-col>
            <v-col cols="4" class="py-0">
              <CustomTextInput
                v-model="locationObj.addressLine1"
                placeholder="Address Line 1"
                :maxlength="150"
              />
            </v-col>
            <v-col cols="4" class="py-0">
              <CustomTextInput
                v-model="locationObj.addressLine2"
                placeholder="Address Line 2"
                :maxlength="150"
              />
            </v-col>
            <v-col cols="4" class="py-0">
              <CustomTextInput
                v-model="locationObj.city"
                placeholder="City"
                :maxlength="30"
              />
            </v-col>
            <v-col cols="2" class="py-0">
              <StateSelect
                :value="locationObj.state"
                header=""
                :selected_country="locationObj.country"
                :use-value-as-label="true"
                @input="(val) => locationObj.state = val"
              />
            </v-col>
            <v-col cols="2" class="py-0">
              <CustomTextInput
                v-model="locationObj.postalCode"
                placeholder="Zip"
                :maxlength="10"
              />
            </v-col>
            <v-col cols="12" sm="4" class="py-0">
              <CountrySelect
                v-model="locationObj.country"
                :has-header="false"
              />
            </v-col>
            <v-col cols="4" class="py-0">
              <v-checkbox
                v-model="locationObj.isDefault"
                label="Is Default"
                color="#2b84eb"
                class="custom-checkbox"
                @change="handleDefaultLocationChange(locationObj)"
              />
            </v-col>
            <v-col cols="4" class="py-0">
              <CustomDropdown
                v-model="locationObj.source"
                placeholder="Source"
                :items="available_sources"
                item-text="name"
                return-object
              />
            </v-col>
          </v-row>
        </v-col>

        <v-col
          cols="1"
          class="d-flex justify-center align-center py-0"
        >
          <v-icon
            class="pointer mb-4"
            medium
            @click="removeLocation(index)"
          >
            mdi-close
          </v-icon>
        </v-col>

        <v-col
          v-if="contact.contactLocations.length > 1"
          cols="12"
        >
          <hr>
        </v-col>
      </v-row>

      <div class="mt-4">
        <a href="javascript:void(0);" class="add-more" @click="addLocation">
          Add a Location +
        </a>
      </div>
    </validation-observer>
  </div>
</template>

<script>
import CustomDatePicker from "@/sharedComponents/CustomDatePicker";
import CustomTextInput from "@/sharedComponents/CustomTextInput";
import CustomDropdown from "@/sharedComponents/CustomDropdown";
import CountrySelect from "@/sharedComponents/CountrySelect";
import StateSelect from "@/sharedComponents/StateSelect";
import datesMixin from "@/utils/datesMixin";

export default {
  name: "Form",
  components: {
    CustomDatePicker,
    CustomTextInput,
    CustomDropdown,
    CountrySelect,
    StateSelect,
  },
  mixins: [datesMixin],
  props: {
    contact: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    available_sources: [],
    customFields: [],
    customFieldDropdowns: {},
  }),
  computed: {
    selectedAccount() {
      return this.$store.getters['user/account'];
    },
    emailDateOptions() {
      return [
        { label: 'Mon', value: 'mon' },
        { label: 'Tue', value: 'tue' },
        { label: 'Wed', value: 'wed' },
        { label: 'Thu', value: 'thu' },
        { label: 'Fri', value: 'fri' },
        { label: 'Sat', value: 'sat' },
        { label: 'Sun', value: 'sun' },
      ];
    },
  },
  async created() {
    await this.getAvailableSources();
    await this.getEditableCustomFields();
  },
  methods: {
    handleDefaultEmailChange(changedObj) {
      if (!changedObj.isDefault) {
        return;
      }

      changedObj.isSubscribed = true;

      const otherDefault = this.contact.contactEmails.find((elem) => {
        return elem.emailAddress !== changedObj.emailAddress && elem.isDefault;
      });

      if (!otherDefault) {
        return;
      }

      otherDefault.isDefault = false;
    },
    handleDefaultPhoneChange(changedObj, type) {
      const defaultField = type === 'phone' ? 'isPhoneDefault' : 'isSmsDefault';
      const subscribedField = type === 'phone' ? 'isPhoneSubscribed' : 'isSmsSubscribed';
      if (!changedObj[defaultField]) {
        return;
      }

      changedObj[subscribedField] = true;

      const otherDefault = this.contact.contactPhones.find((elem) => {
        return elem.phone !== changedObj.phone && elem[defaultField];
      });

      if (!otherDefault) {
        return;
      }

      otherDefault[defaultField] = false;
    },
    handleDefaultLocationChange(changedObj) {
      if (!changedObj.isDefault) {
        return;
      }

      const joinLocation = (loc) => {
        return [
          loc.addressLine1,
          loc.addressLine2,
          loc.city,
          loc.state,
          loc.postalCode,
          loc.country,
        ].join();
      };
      const otherDefault = this.contact.contactEmails.find((elem) => {
        return joinLocation(elem) !== joinLocation(changedObj) && elem.isDefault;
      });

      if (!otherDefault) {
        return;
      }

      otherDefault.isDefault = false;
    },
    async getAvailableSources(){
      this.available_sources = (await this.$rest.source.get_collection({
        ignorePagination: true,
      })).data.items
    },
    async getEditableCustomFields() {
      this.customFields = (await this.$rest.contact.getEditableCustomFields()).data.map((item) => ({
        ...item,
        required: !!item.validationConstraints.find((validationItem) => validationItem.constraint === 'NotBlank'),
      }));
      this.customFields.forEach((field) => {
        if (['choice', 'database', 'yes_or_no'].includes(field.editType)) {
          this.$set(this.customFieldDropdowns, field.fieldName, this.contact.customFields[field.fieldName]);
        }
      });
    },
    async validate() {
      return await this.$refs.textInput.validate() && await this.$refs.textInputLocation.validate() && await this.$refs.textInputEmail.validate() && await this.$refs.textInputPhone.validate();
    },
    addEmail() {
      this.$emit('add-email', true);
    },
    removeEmail(index) {
      this.$emit('remove-email', index);
    },
    addPhone() {
      this.$emit('add-phone', true);
    },
    removePhone(index) {
      this.$emit('remove-phone', index);
    },
    addLocation() {
      this.$emit('add-location', true);
    },
    removeLocation(index) {
      this.$emit('remove-location', index);
    },
    handleCustomFieldDropdownChange(val, fieldName, isNewValueTextBox) {
      if (!isNewValueTextBox) {
        this.$set(this.customFieldDropdowns, fieldName, val);
      }

      if (val === '___ADD_NEW_VALUE___') {
        this.$emit('input', '', 'customFields', fieldName);
      } else {
        this.$emit('input', val, 'customFields', fieldName);
      }
    }
  },
}
</script>

<style lang="scss" scoped>
  @import "@/scss/contact-info-table.scss";

  .pointer {
    cursor: pointer;
  }

  .add-more {
    font-size: 13px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #2b84eb;
  }

  .field__header {
    margin-bottom: 30px;
  }

  .flex-1 {
    flex: 1 1 0;
  }

  ::v-deep .v-input--selection-controls .v-input__slot > .v-label {
    font-family: "Open Sans", sans-serif;
    font-size: 13px;
    color: rgba(0, 0, 0, 0.87);
  }
</style>
