<template>
  <v-container class="mt-3 px-sm-10 px-3" fluid>
    <PageHeader
      header-text="Edit Contact"
    >
      <template #subheader>
        <div>Update the following information and select “Save”</div>
      </template>
    </PageHeader>

    <div
      v-if="contact"
      class="image-heading d-flex align-center"
    >
      <div v-if="!pictureFile" class="upload-background d-flex align-center justify-center mr-8 no-img" @click="$refs.logo_container.click()">
        <input ref="logo_container" type="file" accept=".jpg, .jpeg, .png" style="width: 0; height: 0" @change="handle_file_upload">
        <v-icon size="18" color="66788e">
          mdi-upload-outline
        </v-icon>
      </div>
      <div v-else class="upload-background d-flex align-center justify-center mr-8" @click="$refs.logo_container.click()">
        <img ref="logo_preview" style="max-width: 92px; max-height: 92px;">
        <v-icon size="24" color="warning" style="position: absolute" @click="pictureFile = null">
          mdi-close
        </v-icon>
      </div>

      <div>
        <div class="title-text mb-1">
          Image
        </div>
        <div class="text-field-hint">
          Choose profile image that will be visible to other users
        </div>
      </div>
    </div>

    <Form
      v-if="contact"
      ref="editForm"
      :contact="contact"
      @add-email="addEmail"
      @remove-email="removeEmail"
      @add-phone="addPhone"
      @remove-phone="removePhone"
      @add-location="addLocation"
      @remove-location="removeLocation"
      @input="updateContactFieldValue"
    />

    <v-row
      v-if="contact"
      class="mt-12"
    >
      <v-col cols="12" class="d-flex justify-end">
        <v-btn
          class="custom-button custom-button--blue px-13"
          height="34px"
          depressed
          :block="$vuetify.breakpoint.xsOnly"
          @click="$router.push({
            name: 'ContactDetails',
            params: {
              id: contact.id,
            },
          })"
        >
          Back
        </v-btn>

        <v-btn
          class="custom-button custom-button--blue px-13 ml-5"
          height="34px"
          depressed
          :block="$vuetify.breakpoint.xsOnly"
          @click="save"
        >
          Save
        </v-btn>
      </v-col>
    </v-row>

    <v-dialog
      v-model="isShowingConfirmEmailDelete"
      scrollable
      max-width="360px"
    >
      <DeleteConfirmationDialog
        v-if="isShowingConfirmEmailDelete"
        header="Delete email"
        action_button_name="Confirm"
        @delete="confirmRemoveEmail"
        @dismiss="isShowingConfirmEmailDelete = false;"
      >
        <template #subheader>
          Do you really want to delete this email?<br>
        </template>
      </DeleteConfirmationDialog>
    </v-dialog>

    <v-dialog
      v-model="isShowingConfirmLocationDelete"
      scrollable
      max-width="360px"
    >
      <DeleteConfirmationDialog
        v-if="isShowingConfirmLocationDelete"
        header="Delete location"
        action_button_name="Confirm"
        @delete="confirmRemoveLocation"
        @dismiss="isShowingConfirmLocationDelete = false;"
      >
        <template #subheader>
          Do you really want to delete this location?<br>
        </template>
      </DeleteConfirmationDialog>
    </v-dialog>

    <v-dialog
      v-model="isShowingConfirmPhoneDelete"
      scrollable
      max-width="360px"
    >
      <DeleteConfirmationDialog
        v-if="isShowingConfirmPhoneDelete"
        header="Delete phone"
        action_button_name="Confirm"
        @delete="confirmRemovePhone"
        @dismiss="isShowingConfirmPhoneDelete = false;"
      >
        <template #subheader>
          Do you really want to delete this phone?<br>
        </template>
      </DeleteConfirmationDialog>
    </v-dialog>
  </v-container>
</template>

<script>
import PageHeader from "@/sharedComponents/PageHeader";
import Form from "@/views/Contacts/components/EditContact/Form";
import { parseImageFileToBase64 } from "@/utils/helper";
import DeleteConfirmationDialog from "@/sharedComponents/DeleteConfirmationDialog";

export default {
  name: "EditContact",
  metaInfo: {
    title: 'Edit Contact'
  },
  components: {
    Form,
    PageHeader,
    DeleteConfirmationDialog,
  },
  data() {
    return {
      contact: null,
      pictureFile: null,
      pictureBase64: null,
      isShowingConfirmEmailDelete: false,
      removeEmailIndex: null,
      isShowingConfirmLocationDelete: false,
      removeLocationIndex: null,
      isShowingConfirmPhoneDelete: false,
      removePhoneIndex: null
    };
  },
  async created() {
    await this.get_contact_details(this.$route.params.id);
  },
  methods: {
    async handle_file_upload(event) {
      this.pictureFile = event.target.files[0]
      if (this.pictureFile) {
        let reader = new FileReader();

        reader.onload = ()=> {
          this.$refs.logo_preview.src = reader.result;
        }
        reader.readAsDataURL(this.pictureFile);

        const result = await parseImageFileToBase64(this.pictureFile);
        this.pictureBase64 = {
          data: result.data,
          fileName: result.name,
        };
      }
    },

    async get_contact_details(id) {
      this.contact = (
          await this.$rest.contact.get_contact(id, {groups: ['contact_email','contact_location','contact_phone','contact_segment', 'source']})
      ).data;
      if (this.contact.customFields && Array.isArray(this.contact.customFields)) {
        this.contact.customFields = {}
      }
      this.$set(this.contact, 'source', this.contact.source.id ?? '');
    },

    addEmail() {
      this.contact.contactEmails.push({
        id: null,
        emailAddress: '',
        source: null,
        isDefault: false,
        isSubscribed: false,
      });
    },

    async removeEmail(index) {
      this.removeEmailIndex = index;
      this.isShowingConfirmEmailDelete = true;
    },

    async confirmRemoveEmail() {
      const emailId = this.contact?.contactEmails[this.removeEmailIndex]?.id;
      if (emailId !== null && emailId !== undefined) {
        await this.$rest.contact.delete_contact_emails(emailId);
      }

      this.contact.contactEmails.splice(this.removeEmailIndex, 1);
      this.isShowingConfirmEmailDelete = false;
    },

    addPhone() {
      this.contact.contactPhones.push({
        phone: '',
        isFax: false,
        isPhoneDefault: false,
        isPhoneSubscribed: false,
        isSmsDefault: false,
        isSmsSubscribed: false,
      });
    },

    async removePhone(index) {
      this.removePhoneIndex = index;
      this.isShowingConfirmPhoneDelete = true;
    },

    async confirmRemovePhone() {
      const phoneId = this.contact?.contactPhones[this.removePhoneIndex]?.id;
      if (phoneId !== null && phoneId !== undefined) {
        await this.$rest.contact.delete_contact_phones(phoneId);
      }
      this.contact.contactPhones.splice(this.removePhoneIndex, 1);
      this.isShowingConfirmPhoneDelete = false;
    },

    addLocation() {
      this.contact.contactLocations.push({
        company: '',
        addressLine1: '',
        addressLine2: '',
        city: '',
        state: '',
        postalCode: '',
        country: this.contact.contactLocations[0]?.country,
        isDefault: false,
      });
    },

    async removeLocation(index) {
      this.removeLocationIndex = index;
      this.isShowingConfirmLocationDelete = true;
    },

    async confirmRemoveLocation() {
      const locationId = this.contact?.contactLocations[this.removeLocationIndex]?.id;
      if (locationId !== null && locationId !== undefined) {
        await this.$rest.contact.delete_contact_locations(locationId);
      }
      this.contact.contactLocations.splice(this.removeLocationIndex, 1);
      this.isShowingConfirmLocationDelete = false;
    },

    updateContactFieldValue(val, fieldName, customFieldName) {
      if (customFieldName) {
        this.$set(this.contact.customFields, customFieldName, val);
      } else {
        this.$set(this.contact, fieldName, val);
      }
    },

    async save() {
      if (!await this.$refs.editForm.validate()) {
        return;
      }
      const contactEmails = this.contact.contactEmails.map(contactEmail => ({...contactEmail, source: contactEmail.source?.id}));
      this.contact.emails = contactEmails
      
      const contactLocations = this.contact.contactLocations.map(location => ({...location, source: location.source?.id}));
      this.contact.locations = contactLocations

      const contactPhones = this.contact.contactPhones.map(phone => ({...phone, source: phone.source?.id}));
      this.contact.phones = contactPhones

      const payload = {
        ...this.contact,
        npi: this.contact.npi || null,
        contactEmails,
        contactLocations,
        contactPhones,
        source: this.contact.source,
        requestPictureFile: this.pictureBase64,
        firstName: this.contact.firstName || null,
        lastName: this.contact.lastName || null,
      };

      await this.$rest.contact.put_contact(this.contact.id, payload)
      this.$notifier.success("Successfully saved the contact.");
      this.$router.push({
        name: "ContactDetails",
        params: {
          id: this.contact.id,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.contact {
  &__title-text {
    font-family: "Open Sans", sans-serif;
    font-size: 15px;
    font-weight: 600;
    color: #241c15;
    padding-bottom: 3px;
  }
  &__subtitle-text {
    font-family: "Open Sans", sans-serif;
    font-size: 13px;
    color: #66788e;
    padding-bottom: 10px;

    a {
      color: #2b84eb !important;
    }
  }
}

.custom-button {
  text-transform: none;
  .v-btn__content {
    font-family: "Open Sans", sans-serif;
    font-size: 13px;
    font-weight: 600;
    font-stretch: normal;
    letter-spacing: normal;
  }
  &--blue {
    background: #2b84eb !important;
    .v-btn__content {
      color: white;
    }
  }
  &--gray {
    background: #ecf1f5 !important;
    .v-btn__content {
      color: #66788e;
    }
  }
}

.text-info {
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #66788e;
  margin: 7px 0 0;
}

.profile-pic-text {
  font-size: 15px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}

.upload-background.no-img {
  border: 1px solid #66788e;
  border-radius: 50%;
  padding: 32px;
}

.image-heading {
  margin-top: 50px;
  margin-bottom: 30px;
}
</style>
